<template>
  <!-- <section id="hero" :style="$vuetify.breakpoint.mdAndUp ? 'height: calc(100vh - ' + $vuetify.application.top + 'px)' : ''"> -->
    <section id="hero">
    <div class="d-md-flex mainBackground">
      <v-card flat tile class="d-flex flex-column text-center px-10 mx-auto mainBackground" :style="$vuetify.breakpoint.smAndDown ? 'width: 100%' : 'width: 50%'">
        <!-- <div
          class="text-center hero-company"
          :class="[$vuetify.breakpoint.smAndDown ? 'text-headline' : 'text-h3']"
        >
          The Title Girl
        </div> -->

        <!-- this component can be placed anywhere -->


    <v-container class="text-center">

      <v-responsive class="mx-auto mb-16" width="200">
        <v-divider class="mainBlack" style="border-width: 1px"></v-divider>
      </v-responsive>

      <v-card flat class=" my-8" style="background: none;">
        <v-img src="@/assets/images/6ob.gif" class="">
          <v-row class="gotham-font px-4 mt-md-4 mt-lg-16" style="height: 100%; padding-top: 40px" >
            <!-- <v-col cols="12" md="4" class="pa-3" v-for="({ title, text }, i) in features" :key="i">
              <v-card-title class="text-h3 font-weight-bold mainBlack--text justify-center">{{ title }}</v-card-title>
              <v-card-text class="text-h5 text-center mainBlack--text">{{ text }}</v-card-text>
            </v-col> -->
                          <!-- <v-card-title class="text-h3 font-weight-bold mainBlack--text justify-center">test</v-card-title> -->
                          <h2 class="mt-16 mb-3 text-h3 font-weight-bold  gotham-font "> <br><br>CONGRATULATIONS <br> ON YOUR NEW <br> TESLA!<br><br><br><br><br><br><br></h2>


          </v-row>
        </v-img>
      </v-card>

          <div class="gotham-font mt-12 text-center mainRed--text" :class="[$vuetify.breakpoint.smAndDown ? 'width: 100%' : 'width: 50%']">

       Register Your Tesla Today With Three Easy Steps
   
      </div>
         <div class="gotham-font mt-10 text-left" :class="[$vuetify.breakpoint.smAndDown ? 'width: 100%' : 'width: 50%']">

       1. Create an account below and Complete an Registration Request
       <br>
       2. Send us your documents and Pay Taxes and Fees
       <br>
       3. Receive your Plates!
       
           </div>
    </v-container>

        <div style="margin-top: calc(100vh * 0.1)" >
             <div :style="backgroundImageStyle" class="gotham-font mt-12 text-center" :class="[$vuetify.breakpoint.smAndDown ? 'text-h4' : 'text-h3']">

           

  
    
          </div>
        </div>


        <div style="margin-top: calc(100vh * 0.1)">
             <div class="gotham-font mt-12 text-center" :class="[$vuetify.breakpoint.smAndDown ? 'text-h6' : 'text-h5']">
            The fastest and easiest way to register your TESLA!

            <v-row class="text-center">
      <v-col cols="12">
        <iframe
          src="https://player.vimeo.com/video/543795257"
          width="640"
          height="360"
          frameborder="0"
          allow="autoplay; fullscreen"
          allowfullscreen
        ></iframe>
      </v-col>
 

      <v-col cols="12">
  
      </v-col>
    </v-row> 
    
          </div>
        </div>
        <div class="gotham-font mt-12 text-center mainRed--text" :class="[$vuetify.breakpoint.smAndDown ? 'text-h5' : 'text-h4']">No Lines. Just Plates.</div>
      </v-card>

        <div class="mt-12 px-4 pb-12 mainBackground"  :style="$vuetify.breakpoint.smAndDown ? 'width: 100%' : 'width: 50%'">
        <CreateAccount></CreateAccount>
      </div>

      <!-- <div class="mt-12 px-4 pb-12 mainBackground"  :style="$vuetify.breakpoint.smAndDown ? 'width: 100%' : 'width: 50%'">
        <CreateAccount></CreateAccount>
      </div> -->

         <!-- <div class="mt-12 px-4 pb-12 mainBackground"  :style="$vuetify.breakpoint.smAndDown ? 'width: 100%' : 'width: 50%'"> -->
                        
    </div>
    <!-- <div class="text-center mt-12">
      <v-btn color="mainTextColor" fab outlined @click="$vuetify.goTo('#additional-services')">
        <v-icon>mdi-chevron-double-down</v-icon>
      </v-btn>
    </div> -->
  </section>
</template>

// <script>
// export default {
//   name: 'Hero',
//   components: {
//     CreateAccount: () => import('./CreateAccount.vue'),
//   },
//   data: () => ({}),
// };
// </script>

<script>
export default {
  name: 'Hero',
  components: {
    CreateAccount: () => import('./CreateAccount.vue'),
  },
  data: () => ({}),
};
</script>

<style scoped>
.v-application .hero-company {
  font-size: 30px;
  margin-top: 50px;
  font-family: 'Gotham-Bold', sans-serif !important;
}
.v-application .hero-title {
  font-family: 'Gotham-Bold', sans-serif !important;
}
.text-shadow {
  text-shadow: 2px 2px black;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

.bounce {
  /* animation: bounce 2s infinite; */
}

.action-btn {
  border-color: #e4002b;
}
.action-btn:hover {
  background: #e4002b;
  color: white;
}
</style>
